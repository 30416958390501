.dataTable

  & ul > li

    color:grey

.dataTables_wrapper

  margin-top: 20px !important


table .labelColumn
  width:0px 
  text-align:right
  white-space: nowrap
  border-right: 1px solid grey


table .showRelationalEntity
  th, td
    padding: 5px
    vertical-align: top
    border: 1px solid black