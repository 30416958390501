select.form-control

  display: inline-block !important;

.modal .select2-container

  width 100% !important

.select-container-full

  .select2-container

    width 100% !important