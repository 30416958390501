.modal

  .modal-body

    .jcrop-keymgr

      display none !important

    .jcrop-holder

      max-width: 500px

    .jcrop-selection

      display -webkit-box
      display flex
      -webkit-box-orient horizontal
      -webkit-box-direction normal
      flex-direction row
      justify-content space-around
