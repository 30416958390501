.input-group

  margin-bottom: 0.5em;
  width: 100%;


.form-horizontal

  .form-group

    margin-left: 0


input[type="number"]
  width: 100px !important;

.small-input
  margin-left 5px;
  margin-right 20px;
  width: 50px;


input[type='checkbox']
  margin: 0 5px 0 10px !important;

.input-leser
  select
    width: 100%
