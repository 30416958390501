.note-editor.panel

  margin-bottom 0

.to-summernote

  background-color $buttonBlue

  .glyphicon

    color white

  &:hover

    cursor pointer


.ui-dialog

  z-index: 1000;
