arabOption()

  font-family Coranica
  font-size 1.5em
  direction rtl
  content attr(arab)
  font-variant-ligatures: none;
  -moz-font-feature-settings: "liga" 0, "clig" 0, "calt" 0;
  -ms-font-feature-settings: "liga" 0, "clig" 0, "calt" 0;
  -webkit-font-feature-settings: "liga" 0, "clig" 0, "calt" 0;
  font-feature-settings: "liga" 0, "clig" 0, "calt" 0;

.arab, .rtl

  direction rtl
  font-family Coranica
  font-variant-ligatures: none;
  -moz-font-feature-settings: "liga" 0, "clig" 0, "calt" 0;
  -ms-font-feature-settings: "liga" 0, "clig" 0, "calt" 0;
  -webkit-font-feature-settings: "liga" 0, "clig" 0, "calt" 0;
  font-feature-settings: "liga" 0, "clig" 0, "calt" 0;

.arab-word

  font-size 2em



.arab-option::after
  arabOption()





