.modal-annotation-thumbnail

  max-width 500px;

.modal
  li, p
    color black !important;

.modal-button
  margin -10 0 0 10 ;
