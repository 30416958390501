.flex-container

  display -webkit-box
  display flex

  .flex-item

    -webkit-box-flex 1
    flex 1

  .flex-item-1

    -webkit-box-flex 1
    flex 1

  .flex-item-2

    -webkit-box-flex 2
    flex 2

  .flex-item-3

    -webkit-box-flex 3
    flex 3


.space-around

  display -webkit-box
  display flex
  justify-content space-around

.space-between

  display -webkit-box
  display flex
  -webkit-box-pack justify;
  justify-content space-between

.align-center

  -webkit-box-align center;
  align-items center