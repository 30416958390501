h1 ul.dropdown-menu

  height: auto
  max-height: 200px
  overflow-x: hidden

.scrollable
  height: auto
  max-height: 200px
  overflow-x: hidden
