.Textstelle

  display: none


  +has(title)
    cursor: pointer

  +has(placeholder)
    cursor: pointer
