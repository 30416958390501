.collapse.in {
  display: inline-block !important;
}

.alert {
  margin-top: 3rem !important;
}

div.container {
  margin-top: 3rem !important;
}

@media (max-width: 330px) {
  div.container {
    margin-top: 40rem !important;
  }
}

@media (min-width: 330px) {
  div.container {
    margin-top: 35rem !important;
  }
}

@media (min-width: 383px) {
  div.container {
    margin-top: 30rem !important;
  }
}

@media (min-width: 392px) {
  div.container {
    margin-top: 25rem !important;
  }
}

@media (min-width: 484px) {
  div.container {
    margin-top: 20rem !important;
  }
}

@media (min-width: 573px) {
  div.container {
    margin-top: 15rem !important;
  }
}

@media (min-width: 822px) {
  div.container {
    margin-top: 10rem !important;
  }
}

@media (min-width: 1543px) {
  div.container {
    margin-top: 5rem !important;
  }
}