ul.nav li.dropdown:hover ul.dropdown-menu

  display: block
  margin-top:0px

.sidebar

  position: relative

@media (min-width: 1120px)

  .sidebar

    position fixed


.navbar-right {
  right: 0;
  left: auto;
}

.navbar-collapse.in {
  overflow-y: auto;
  display: inline-block !important;
}