@font-face {
  font-family: 'Coranica';
  src: url('../../fonts/coranica_1145.eot');
  src: url('../../fonts/coranica_1145.eot?#iefix') format('embedded-opentype'),
          url('../../fonts/coranica_1145.woff2') format('woff2'),
          url('../../fonts/coranica_1145.woff') format('woff'),
          url('../../fonts/coranica_1145.ttf') format('truetype'),
          url('../../fonts/coranica_1145.svg#coranicaregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
